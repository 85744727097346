@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Light.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Medium.ttf");
  font-weight: 600;
}

body {
  line-height: 1.6;
  font-size: 15px;
  color: var(--dark);
  font-family: "Montserrat", serif;
  font-weight: 100;
}

ul {
  padding: 0;
}

ul > li {
  margin: 16px;
}

.orange {
  color: var(--orange)
}

:root {
  --dark: #1c1a1a;
  --grey: #999999;
  --lightBlue: #d6dee1;
  --lightGrey: #dadada;
  --orange: #e68731;
  --green: #229ea2;
  --blue: #29a0c0;
  --backgroudGrey: #f7f7f7;
}
